const detailsSummaryAccordionAnim = () => {
  const detailsContents = document.querySelectorAll('details')
  const RUNNING_VALUE = 'running'

  detailsContents.forEach((details) => {
    const summary = details.querySelector('summary')
    const wrapper = document.createElement('div')
    const inner = document.createElement('div')
    wrapper.appendChild(inner)
    details.appendChild(wrapper)
    Array.from(details.childNodes).forEach((node) => {
      if (node !== summary && node !== wrapper) {
        inner.appendChild(node)
      }
    })

    details.addEventListener('click', (e) => {
      e.preventDefault()

      if (details.dataset.animStatus === RUNNING_VALUE) {
        return
      }

      if (details.open) {
        details.dataset.animStatus = RUNNING_VALUE
        details.removeAttribute('data-accordion')
        const closingAnim = wrapper.animate(closingAnimKeyframes(wrapper), animTiming)
        closingAnim.onfinish = () => {
          details.removeAttribute('open')
          details.removeAttribute('data-anim-status')
        }
      } else {
        details.dataset.accordion = 'open';
        details.setAttribute('open', '');
        details.dataset.animStatus = RUNNING_VALUE;
        const openingAnim = wrapper.animate(openingAnimKeyframes(wrapper), animTiming);
        openingAnim.onfinish = () => {
          details.removeAttribute('data-anim-status');
        }
      }
    })
  });

  const ctas = document.querySelectorAll('details a, details details')

  ctas.forEach((cta) => {
    cta.addEventListener('click', (e) => {
      e.stopPropagation()
    })
  })
}

const animTiming = {
  duration: 300,
  easing: 'ease'
};

const openingAnimKeyframes = (content) => [
  {
    height: 0,
    opacity: 0
  },
  {
    height: content.offsetHeight + 'px',
    opacity: 1
  }
];

const closingAnimKeyframes = (content) => [
  {
    height: content.offsetHeight + 'px',
    opacity: 1
  },
  {
    height: 0,
    opacity: 0
  }
];

export default detailsSummaryAccordionAnim;